<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Profile</div>
      <div class="header-rg">
        <router-link tag="button" class="btn btn-outlined" to="/profile/profile-edit/main">
          Edit information
        </router-link>
      </div>
    </header>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div v-if="!loading_content">
          <div class="lab-dash-block lb-mb-20">
            <div class="row row-32 nowrap">
              <template v-if="userMainInfo">
                <div class="col-w-100">
                  <div class="lb-mb-15">
                    <div class="color-dark lb-mb-4 h2">{{userMainInfo.name}}</div>
                    <!--<div v-if="userMainInfo.currentJob" class="color-grey">{{userMainInfo.currentJob}}</div>-->
                    <div class="color-grey">Coach</div>
                  </div>
                  <div class="lb-mb-15" v-if="userMainInfo.tags.length > 0">
                    <div class="badge-ml-wrapper">
                  <span
                    class="badge-ml bg-grey color-dark-2"
                    v-for="(item, i) in userMainInfo.tags"
                    :key="i"
                  >
                    {{item.title}}</span>
                    </div>
                  </div>
                  <div v-if="userMainInfo.background" class="lb-mb-15 color-dark text-md">
                    <p>{{userMainInfo.background}}</p>
                  </div>
                  <div class="lb-mb-8 h4 color-grey">Personal info</div>
                  <table class="table min-first-col text-md">
                    <tbody>
                    <tr v-if="userMainInfo.email">
                      <td>E-mail</td>
                      <td><span class="text-media color-dark">{{userMainInfo.email}}</span></td>
                    </tr>
                    <tr v-if="userMainInfo.location">
                      <td>Location</td>
                      <td><span class="text-media color-dark">{{userMainInfo.location}}</span></td>
                    </tr>
                    <tr v-if="userMainInfo.langs.length > 0">
                      <td>Languages</td>
                      <td>
                      <span
                        class="text-media color-dark" v-for="(item, i) in userMainInfo.langs"
                        :key="i">
                        {{item}}<span v-if="userMainInfo.langs.length - 1 > i">,</span>
                      </span>
                      </td>
                    </tr>
                    <tr v-if="userMainInfo.ln">
                      <td>LinkedIn</td>
                      <td><a :href="userMainInfo.ln" class="btn btn-link">{{userMainInfo.ln}}</a></td>
                    </tr>
                    <tr v-if="userMainInfo.fb">
                      <td>Facebook</td>
                      <td><a :href="userMainInfo.fb" class="btn btn-link">{{userMainInfo.fb}}</a></td>
                    </tr>
                    <tr v-if="userMainInfo.inst">
                      <td>Instagram</td>
                      <td><a :href="userMainInfo.inst" class="btn btn-link">{{userMainInfo.inst}}</a></td>
                    </tr>
                    <tr v-if="userMainInfo.website">
                      <td>Website</td>
                      <td><a :href="userMainInfo.website" class="btn btn-link">{{userMainInfo.website}}</a></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col">
                  <div class="lab-dash-image-user size-two">
                    <img v-if="userMainInfo.photo" :src="userMainInfo.photo" alt="image">
                    <img v-else src="@/assets/img/default-user-avatar.png" alt="iamge">
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="lab-dash-block mb-bt">
            <div class="lab-dash-title mb-bt">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="title text-media h3">Information</div>
                </div>
              </div>
            </div>
            <div >
              <table class="table min-first-col2 text-md">
                <tbody>
                <template v-if="information">
                  <tr v-if="information.a">
                    <td>{{information.a.title}}</td>
                    <td>
                    <span class="text-media color-dark">
                      <span
                        class="info-list-c"
                        v-for="(option, a) in information.a.options.filter(e => e.selected === true)"
                        :key="a + 'c'"
                      >
                        {{option.title}}<span>,</span>
                      </span>
                    </span>
                    </td>
                  </tr>
                  <tr v-if="information.b">
                    <td>{{information.b.title}}</td>
                    <td>
                    <span class="text-media color-dark">
                      <span
                        class="info-list-c"
                        v-for="(option, a) in information.b.options.filter(e => e.selected === true)"
                        :key="a + 'c'"
                      >
                        {{option.title}}<span>,</span>
                      </span>
                    </span>
                    </td>
                  </tr>
                  <tr v-if="information.c">
                    <td>{{information.c.title}}</td>
                    <td>
                    <span class="text-media color-dark">
                      <span
                        class="info-list-c"
                        v-for="(option, a) in information.c.options.filter(e => e.selected === true)"
                        :key="a + 'c'"
                      >
                        {{option.title}}<span>,</span>
                      </span>
                    </span>
                    </td>
                  </tr>
                </template>
                <template v-if="user_achievements">
                  <tr v-if="achievements.since">
                    <td>Coach since</td>
                    <td> <span class="text-media color-dark">{{dateDay(achievements.since).date}}</span></td>
                  </tr>
                  <tr v-if="achievements.school">
                    <td>Name of coaching school</td>
                    <td> <span class="text-media color-dark">{{achievements.school}}</span></td>
                  </tr>
                  <tr v-if="user_achievements.certifications.length && options_cert.length">
                    <td>Coaching certification</td>
                    <td>
                      <span class="text-media color-dark">
                        <span
                          class="info-list-c"
                          v-for="(item, i) in user_achievements.certifications"
                          :key="i + 's'"
                        >
                          {{options_cert.find((e) => +item.cert_id === +e.id).title}}<span>, </span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="achievements.federation_id && options_fed.length">
                    <td>Coaching federation member</td>
                    <td>
                      <span class="text-media color-dark">
                        {{options_fed.find((e) => +achievements.federation_id === +e.id).title}}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="user_achievements.diplom && user_achievements.diplom.diplom_name">
                    <td>Coaching school diploma as business coach</td>
                    <td><a :href="user_achievements.diplom.diplom_url" class="btn btn-link"><span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span><span>{{user_achievements.diplom.diplom_name}}</span></a></td>
                  </tr>
                  <tr v-if="achievements.federation_role">
                    <td>Do you have a role in a coaching federation?</td>
                    <td> <span class="text-media color-dark">{{achievements.federation_role}}</span></td>
                  </tr>
                  <tr v-if="achievements.management_experience">
                    <td>Years of management experience</td>
                    <td> <span class="text-media color-dark">{{achievements.management_experience}}</span></td>
                  </tr>
                  <tr v-if="achievements.leadership_experience">
                    <td>Years of leadership experience</td>
                    <td> <span class="text-media color-dark">{{achievements.leadership_experience}}</span></td>
                  </tr>
                  <tr v-if="achievements.leadership_experience">
                    <td>How many years have you led a group of people or an organisation?</td>
                    <td> <span class="text-media color-dark">{{achievements.led_a_group}}</span></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row row-32 card-wrap">
            <template v-if="projects">
              <div class="col-lg-6" v-for="(item, i) in projects" :key="i">
                <div class="card type-two bg-white">
                  <div class="card-header">
                    <div class="card-image">
                      <img v-if="item.image && checkURL(item.image)" :src="item.image" alt="image">
                      <img v-else src="@/assets/img/image-placeholder.jpg" alt="image">
                    </div>
                    <div v-if="item.link" class="lb-mb-15">
                      <div class="badge-ml-wrapper">
                  <span class="badge-ml bg-grey color-dark-2 text-sm badge-icon">
                    <span class="icon"><img src="@/assets/img/icons/icon-link.svg" alt="icon"></span>
                    <span class="link" v-html="item.link"></span>
                  </span>
                      </div>
                    </div>
                    <div class="card-title lb-mb-8 h4"><span class="max-l-a">{{item.title}}</span></div>
                    <div class="card-text text-md color-grey text-with-editor">
                      <div v-html="item.body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="loader-wrapper">
          <div class="loader-circle">
            <img src="@/assets/img/loader-icon.png" alt="loader">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading_prof: false,
      loading_proj: false,
      loading_info: false,
      loading_ach: false,
      loading_fed: false,
      loading_cert: false
    }
  },
  computed: {
    userMainInfo () {
      return this.$store.getters.user
    },
    projects () {
      return this.$store.getters.projects
    },
    information () {
      return this.$store.getters.user_information
    },
    achievements () {
      return this.user_achievements.achievments
    },
    user_achievements () {
      return this.$store.getters.user_achievements
    },
    options_fed () {
      return this.$store.getters.options_federation
    },
    options_cert () {
      return this.$store.getters.options_cert
    },
    loading_content () {
      if (!this.loading_proj && !this.loading_prof && !this.loading_info && !this.loading_ach && !this.loading_fed && !this.loading_cert) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    checkURL: function (url) {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    fetchContent: function () {
      if (this.userMainInfo === '') {
        this.loading_prof = true
      }
      this.$store.dispatch('fetchUserInfo')
        .finally(() => {
          this.loading_prof = false
        })
      if (this.information === '') {
        this.loading_info = true
      }
      this.$store.dispatch('fetchUserInformation')
        .finally(() => {
          this.loading_info = false
        })
      if (this.projects === '') {
        this.loading_proj = true
      }
      this.$store.dispatch('fetchProjects')
        .finally(() => {
          this.loading_proj = false
        })
      if (this.options_fed.length === 0) {
        this.loading_fed = true
      }
      this.$store.dispatch('fetchOptionsFederation')
        .finally(() => {
          this.loading_fed = false
        })
      if (this.options_cert.length === 0) {
        this.loading_cert = true
      }
      this.$store.dispatch('fetchOptionsCert')
        .finally(() => {
          this.loading_cert = false
        })
      if (this.user_achievements === '') {
        this.loading_ach = true
      }
      this.$store.dispatch('fetchUserAchievements', false)
        .finally(() => {
          this.loading_ach = false
        })
    },
    dateDay (e) {
      const t = new Date(e)
      const date = ('0' + t.getDate()).slice(-2) + ' ' + this.getNameMonth(t.getMonth()) + ', ' + t.getFullYear()
      const d = {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth()),
        hours: ('0' + t.getHours()).slice(-2),
        minutes: ('0' + t.getMinutes()).slice(-2)
      }
      return d
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    }
  },
  created () {
    if (this.$route.query.usr) {
      this.$router.push(this.$route.path)
    }
  },
  mounted () {
    this.fetchContent()
  }
}
</script>

<style lang="scss">
.info-list-c:last-child{
  span{
    display: none;
  }
}
</style>
